.logoContainer {
  // margin: 24px;
  display: flex;
  height: 61px;

  &.csm {
    height: 36px;
  }

  img.logo {
    margin-right: 5px;
  }

  img.logoText {
    opacity: 1;
    width: 100%;
    margin: auto 0;
    transition: all 0.1s ease-in-out;
    &.collapsed {
      opacity: 0;
    }
  }
}
