.strategy-resource-identifier {
  border-top: 0px !important;
  .ag-header-group-cell-label {
    display: grid;
    text-align: center;
  }
  .ag-header-cell-text {
    color: #000 !important;
  }
}

.strategy-bid-header {
  background-color: #b4c6e7;
  padding: 5px 6px !important;
  text-align: center;
  text-transform: capitalize !important;
  border-right: 1px solid #fff !important;
  .ag-header-group-cell-label,
  .ag-header-cell-label {
    display: grid;
    text-align: center;
  }
  .ag-header-cell-text {
    color: #000 !important;
  }
}

.strategy-offer-header {
  background-color: #c6e0b4;
  padding: 5px 6px !important;
  text-align: center;
  text-transform: capitalize !important;
  border-right: 1px solid #fff !important;
  .ag-header-group-cell-label,
  .ag-header-cell-label {
    display: grid;
    text-align: center;
  }
  .ag-header-cell-text {
    color: #000 !important;
  }
}

.strategy-bid-cell {
  background-color: #d9e1f2;
  text-align: center;
}

.strategy-bid-cell-currency {
  background-color: #d9e1f2;
  text-align: right;
}

.strategy-offer-cell {
  background-color: #e2efda;
  text-align: center;
}

.strategy-offer-cell-currency {
  background-color: #e2efda;
  text-align: right;
}

.participant-strategy {
  color: #0c1725 !important;
  background-color: #cdd2d9 !important;
}

.toolBarOptions {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  justify-content: space-between;
  padding-top: 3px;
  margin-right: 1rem;
}

.ag-cell {
  border-right: 1px solid #fff !important;
}
