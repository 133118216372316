.sideBar {
  height: 100%;
  overflow: hidden;
  background-color: var(--primary) !important;
  box-sizing: border-box;
}

div[id='sidebar-menu'].sidebarMenu {
  color: #ffffff;
}

button[data-testid='colapsebutton'].colapsebutton {
  box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.15);
  border-radius: 8px 0px 0px 8px;
  width: 40px;
  min-width: 40px;
  min-height: 40px;
  position: absolute;
  right: 0;
  bottom: 48px;
  background-color: #232e3d;

  //
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;

  color: #ffffff;

  :first-child {
    margin: 0;
  }
}

div[id='menuTitle'].menuTitle {
  opacity: 1;
  transition: all 0.2s ease-in-out;
  margin-top: 20px;
  margin-bottom: 20px;
  span {
    color: #ffffff;
  }

  &.collapsed {
    opacity: 0;
  }
}

div[id='listItemIcon'].itemIcon {
  padding-left: 1rem;
  min-width: 49px;
  transition: all 0.5s ease-in-out;

  &.collapsed {
    padding-left: 1.17rem;
  }

  &:hover {
    color: #468388;
  }
}

@keyframes move-left {
  /* You could think of as "step 1" */
  0% {
    position: relative;
  }
  /* You could think of as "step 2" */
  100% {
    position: absolute;
  }
}

div[id='listItemButton'].menuButton {
  height: 40px;
  padding-top: 32px;
  padding-bottom: 32px;

  &.full {
    padding-right: 0;

    a {
      width: 100%;
      position: relative;

      &.fullLink {
        animation: move-left 0.9s forwards 1;
        left: 0;
      }
    }
  }

  &:hover {
    svg,
    span {
      color: #468388 !important;
    }
  }
}

.selectedMenu {
  background-color: #232e3d;
}

div[id='listItemButton'].selectedTab {
  color: #468388;

  div[id='menuTitle'].menuTitle {
    span {
      color: #468388;
      font-weight: 800;
    }
  }
}

.tabIcon {
  color: #ffffff;

  &.selectedIconTab {
    color: #468388;
  }

  &:hover {
    color: #468388 !important;
  }
}

div[id='listItemText'].subMenuText {
  padding-left: 4rem;
  margin: 0 0 1rem 0;

  &.subTabSelected {
    span {
      color: #a9dfe4;
      font-size: 0.9rem;
    }
  }

  span {
    color: #ffffff;
    font-size: 0.9rem;

    &:hover {
      color: #468388;
    }
  }
}

div[id='collapsedSubMenu'] {
  &.subMenuCollapsedContainer {
    & :global .MuiPopover-paper {
      margin-left: 5px;
      padding-left: 10px;
      background-color: transparent;
      border: none;
      box-shadow: 12px 16px 10px -11px rgb(0 0 0 / 25%);
      border-radius: 8px;

      &::after {
        content: '';
        position: absolute;
        top: 8px;
        left: 4px;
        width: 0;
        height: 0;
        border-top: solid 6px transparent;
        border-bottom: solid 6px transparent;
        border-right: solid 6px #ffffff;
      }

      ul {
        min-width: 149px;
        background-color: #ffffff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
      }
    }
  }
}

div[id='listItemText'].subMenuCollapsedText {
  margin: 0 0 1rem 0;

  span {
    color: #0e0e2c;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;

    &:hover {
      color: #7fcfd7;
      font-weight: 700;
    }
  }
}
