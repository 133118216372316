.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  max-height: 80px;
  margin-left: 207px;
  transition: all 0.5s ease;
}

.aside {
  z-index: 1;
  max-width: 207px;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.5s ease;
}

.collapsed {
  max-width: 100px;
}

.main {
  height: 92vh;
  margin-left: 207px;
  transition: all 0.5s ease;
}

.full {
  margin-left: 100px;
}
