.supportCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: #ffffff;
  vertical-align: top;
  gap: 16px;

  &Title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: #213955;

    padding: 24px 24px 0px 24px;
  }

  &Item {
    display: grid;
    grid-template-columns: 1fr, auto, 1fr;
    width: 100%;
    gap: 16px;

    &Container {
      margin: 0px 24px 0px 24px;
      box-sizing: border-box;
      height: 112px;
      border: 1px solid #cdd2d9;
      border-radius: 8px;
    }

    &Icon {
      width: 18px;
      height: 18px;
      top: 3px;
      left: 3px;
      color: #468388;
    }
  }
}
