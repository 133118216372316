.tenantPage {
  width: 100%;
  height: 100%;
}
.nameColumn {
  display: grid;
}
.name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #25213b;
}
.userName {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #354a63;
}

.tableToolbar {
  border: 1px solid #cdd2d9;
  box-sizing: border-box;
}
.tenantWrapper {
  padding: 33px 33px 33px 16px;
  height: 100vh;
  background-color: var(--page-background);
}
.addTenantBtn {
  color: #468388 !important;
  box-shadow: none !important;
  text-transform: none !important;
  background-color: transparent !important;
  border-radius: 8px !important;
  max-height: 42px;

  &:hover {
    background: #468388 !important;
    color: white !important;
  }

  &.disabled {
    color: #7e8c9d !important;
  }

  & + .toolbarbtn {
    margin-left: 5px;
  }
}

.tablePagination {
  p,
  div {
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    letter-spacing: 0.05em !important;
    color: #69798c !important;
  }

  p {
    margin: 0;
  }
}

.tableRowHead {
  background-color: #f2f5f7;

  .tableCell {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    letter-spacing: 0.05em !important;
    text-transform: uppercase !important;
    padding: 8px 11px 8px 24px;
    color: #8895a4;
  }
}

.tableRowBody {
  td {
    color: #232e3d !important;
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 28px !important;
    padding-left: 24px !important;
  }

  a {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #568d91;
  }
}
