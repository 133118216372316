.pageInfoCard {
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // align-items: center;
  // padding: 113px 226px;

  // position: absolute;
  // width: 602px;
  // height: 252px;
  // left: 481px;
  // top: 341px;

  position: absolute;
  top: 50%;
  transform: translate(36%, -50%);

  margin: 0 auto;
  width: 50%;
  height: 252px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 20px;

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;

  background: #ffffff;
  border-radius: 16px;
}
