.topbar-wrapper {
  position: relative;
}
.topbar {
  background-color: #ffffff !important;
  max-height: 70px;
  .menu-icon {
    color: var(--bodyText);
  }
}
.logoutbtn {
  width: 150px;
  color: #468388 !important;
  box-sizing: border-box;
  box-shadow: none !important;
  text-transform: none !important;
  background-color: transparent !important;
  border: 1px solid #468388 !important;
  border-radius: 8px !important;
  height: 40px !important;

  &:hover {
    background: #468388 !important;
    color: white !important;
  }
}
