.container {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 10%;
  }
  margin-bottom: 2rem;
}

.progress {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
