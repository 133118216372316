.matchedtrades {
  display: grid;
  grid-template-rows: auto;
  width: 100%;
  height: 97%;
  gap: 5px;
}

.matchedtradesoptions {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  justify-content: space-between;
  padding-top: 3px;
  margin-right: 1rem;
}

.intervalOptions {
  display: flex;
  gap: 28px;
}

.filterBtn {
  color: #468388 !important;
  box-shadow: none !important;
  text-transform: none !important;
  background-color: transparent !important;
  border-radius: 8px !important;
  max-height: 42px;

  &:hover {
    background: #468388 !important;
    color: white !important;
  }

  &.disabled {
    color: #7e8c9d !important;
  }

  & + .toolbarbtn {
    margin-left: 5px;
  }
}
