span.switchRoot {
  padding: 0px;
  width: 76px;
  height: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
}

span.switchRoot .thumb {
  box-sizing: border-box;
  width: 20px;
  height: 20px;
}

span.switchRoot .base {
  padding: 0px;
  margin: 2px;
}

span.switchRoot .input {
  left: -250%;
  width: 700%;
}

span.switchRoot .checked {
  transform: translateX(52px);
  + .track {
    &::before {
      transform: translateY(-100%);
    }
    &::after {
      transform: translateY(0%);
    }
  }
}

span.switchRoot .track {
  border-radius: calc(24px / 2);
  &:before,
  &:after {
    color: white;
    position: absolute;
    top: -9%;
    // width: 16px;
    // height: 16px;
  }

  &:after {
    content: 'Active';
    left: 12%;
    transform: translateY(-100%);
  }
  &:before {
    content: 'Inactive';
    right: 6%;
    transform: translateY(0%);
  }
}
