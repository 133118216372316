@import url('https://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext');

:root {
  --primary: #213955;
  --secondary: #468388;
  --tertiary: #b31d3b;
  --dark: #0e0e2c;
  --blue2shade: #69798c;
  --success: #7fcfd7;
  --bodyText: #0c1725;
  --subtleText: #7e8c9d;
  --accent: #f2f5f7;
  --light: #fafcfe;
}

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

html,
body {
  margin: 0px;
  height: 100%;
  // background-color: var(--page-background);
}

body {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  font-family: Lato;
}

h1 {
  font-weight: bold;
  font-size: 64px;
  letter-spacing: -2%;
}

h2 {
  font-weight: bold;
  font-size: 40px;
  letter-spacing: -2%;
}

h3 {
  font-weight: bold;
  font-size: 24px;
  letter-spacing: -2%;
}

.subtitle {
  font-family: 'Lato';
  font-weight: 500;
  font-size: 24px;
}

.inputLabel {
  font-family: 'Lato';
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.small {
  font-weight: 500 !important;
  font-size: 12px !important;
}

.pretitle {
  font-weight: bold !important;
  font-size: 10px !important;
  letter-spacing: 0.03em !important;
}

.button {
  font-family: 'Work Sans' !important;
  font-weight: bold !important;
  font-size: 10px !important;
  letter-spacing: 0.03em !important;
}

a {
  font-family: 'Work Sans';
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
}

.MuiClockPicker-root {
  span[aria-label='05 minutes'],
  span[aria-label='10 minutes'],
  span[aria-label='20 minutes'],
  span[aria-label='25 minutes'],
  span[aria-label='35 minutes'],
  span[aria-label='40 minutes'],
  span[aria-label='50 minutes'],
  span[aria-label='55 minutes'] {
    color: #cdcdcd;
  }
}
