.container {
  border: 0.5px solid #d6d6d6;
  box-sizing: border-box;
  border-radius: 8px;
  &.fullH {
    height: 100%;
  }
}

.gridContainer {
  height: 92%;
  border-radius: 0px 8px;
  min-height: 400px;

  & div[class='ag-theme-alpine pci-grid'] {
    height: 100% !important;
    background: #f2f5f7;
    border: 0.5px solid #d6d6d6;
    box-sizing: border-box;
  }

  & div[class='action-button-close'] {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: transparent;
    margin: 0;
    padding: 0;
  }

  & div[class='ag-header-cell'] {
    white-space: pre !important;
    height: auto !important;
  }

  & div[class='ag-header-cell-label'] {
    /* Necessary to allow for text to grow vertically */
    height: auto !important;

    & span[class='ag-header-cell-text'] {
      white-space: pre-wrap !important;
    }
  }
}
