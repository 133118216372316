.loadingCover {
  background-color: white;
  position: absolute;
  width: 97%;
  height: 89%;
  bottom: 0;
  padding-left: 47%;
  padding-top: 11%;
}

.table-toolbar-resources {
  background-color: #ffffff;
  border-bottom: 1px solid #cdd2d9 !important;
  border-radius: 8px 8px 0px 0px;
  box-sizing: border-box;
  min-height: 50px !important;
  &.editing {
    background-color: #e2f4f6;
  }
}
.resources-btn {
  /*  text-transform: none !important;
	color: #468388 !important;
	font-size: 14px !important; */

  color: #468388 !important;
  box-shadow: none !important;
  text-transform: none !important;
  background-color: transparent !important;
  border-radius: 8px !important;
  max-height: 42px;

  &:hover {
    background: #468388 !important;
    color: white !important;
  }

  &.disabled {
    color: #7e8c9d !important;
  }

  & + .resources-btn {
    margin-left: 5px;
  }
}

.resources-btn-add-row {
  position: absolute;
  bottom: 10px;
  left: 10px;

  color: #468388;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: 0;
  background: none;
  border-radius: 20px;
  cursor: pointer;
}

.resources-btn-close-row {
  background: none;
  border: 0;
  color: #468388;
  cursor: pointer;
  padding-top: 2.5rem;
}

.resources-toolbar-wrapper {
  width: 75%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.resources-toolbar-text {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;

  color: #213955;
  flex: 1;
}

.pci-grid {
  box-sizing: border-box;
  transition: border 250ms ease-in;
  border: 0 !important;
  height: auto !important;
  overflow: auto;
  max-height: calc(100vh - 185px);

  /* Hide right header and spacer */
  .ag-pinned-right-header,
  .ag-horizontal-right-spacer {
    width: 0 !important;
    min-width: 0 !important;
  }
  .ag-root-wrapper {
    height: inherit;
    border: 0 !important;
  }

  .ag-root-wrapper-body {
    height: inherit;
  }

  /* Add absolute position so that action buttons column will appear on top of other columns. pointer-events: none to pass on mousemove event to behind columns */
  .ag-pinned-right-cols-container {
    position: absolute !important;
    left: 0px;
    pointer-events: none;
  }
  /* Reset pointer-events so that click can happen on action buttons */
  .ag-pinned-right-cols-container * {
    pointer-events: initial;
  }

  /* Hide border of right-cols-container */
  .ag-pinned-right-cols-container .ag-cell {
    border: none !important;
  }

  /* Show action buttons only for row that is being hovered. For rows which are not being hovered, hide them by setting their width and padding to 0.*/

  &.no-rows {
    height: 80vh !important;
  }

  & {
    border-top: 1px solid #2cb2be;

    .ag-pinned-right-cols-container {
      left: 0;
    }

    .ag-pinned-right-cols-container .ag-row {
      background: none;
      width: 10px;
      height: 10px;
      top: 18px;
      left: 0px;
      transition: all 250ms ease-in-out;
    }

    .action-button-close {
      width: 20px;
      display: flex;
      justify-content: center;
      position: relative;
      background: transparent;
      margin: 0;
      padding: 0;
      height: 20px;
    }

    .ag-row-inline-editing .action-button-close {
      left: -30px !important;
    }
  }
}

.table-pagination {
  p,
  div {
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 17px !important;
    letter-spacing: 0.05em !important;
    color: #69798c !important;
  }

  p {
    margin: 0;
  }
}

div.ag-header-viewport {
  text-transform: capitalize;
  background-color: #f2f5f7;
  font-family: Lato !important;
  font-size: 12px !important;
  .ag-header-cell {
    padding: 11px 2px 11px 2px;
    color: #8895a4;
  }
}

.resources-list-grid .ag-center-cols-container {
  width: 100% !important;
}

.success-variant {
  color: #213955 !important;
  background-color: #f2f5f7 !important;
  border: 1px solid #213955 !important;
}
.error-variant {
  color: #b31d3b !important;
  background-color: #f2f5f7 !important;
  border: 1px solid #b31d3b !important;
}
.info-variant {
  border: 1px solid #7fcfd7;
  background-color: #f2f5f7 !important;
  border: 1px solid #7fcfd7 !important;
}

.ag-row {
  td {
    color: #232e3d !important;
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 28px !important;
  }

  a {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #568d91;
  }
}

.pci-grid {
  .ag-body-viewport {
    .ag-cell-wrapper,
    .ag-cell-inline-editing,
    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
      overflow: hidden;
      height: 100%;
      width: 100%;
    }
  }
}
