div[id='breadcrumbs'].breadcrumbs {
  a,
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
  }
}
