.tableToolbar {
  background-color: #ffffff;
  border-bottom: 1px solid #cdd2d9 !important;
  border-radius: 8px 8px 0px 0px;
  box-sizing: border-box;
  min-height: 50px !important;
  &.editing {
    background-color: #e2f4f6;
  }
}

.toolbarWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleWrapper {
  flex: 1 1 50%;
  // margin-top: 1rem;
  span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #0c1725;
  }
}

.toolbarbtn {
  color: #468388 !important;
  box-shadow: none !important;
  text-transform: none !important;
  background-color: transparent !important;
  border-radius: 8px !important;
  max-height: 42px;

  &:hover {
    background: #468388 !important;
    color: white !important;
  }

  &.disabled {
    color: #7e8c9d !important;
  }

  & + .toolbarbtn {
    margin-left: 5px;
  }
}
