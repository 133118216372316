@import '~ag-grid-community/src/styles/ag-grid.scss';
@import '~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      font-family: 'Lato',
      font-size: 12px,
    )
  );

  .ag-cell {
    border-right: 1px solid ag-param(border-color) !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .ag-header-cell {
    text-align: center !important;
    padding: 11px 2px 11px 4px !important;
    border-right: 1px solid ag-param(border-color);
  }

  .ag-cell-aligned-center {
    text-align: center;
  }

  .ag-cell-text-capitalized {
    text-transform: capitalize;
  }

  .ag-header-cell-aligned {
    &-center {
      .ag-header-cell-label,
      .ag-header-group-cell-label {
        text-align: center;
        justify-content: center;
      }
    }
  }

  .ag-header-cell-label {
    text-align: center;
    text-transform: capitalize !important;
    color: var(--subtleText);
    font-size: 12px;
    font-weight: 400;
  }

  .ag-header-cell-filtered {
    background-color: #315680 !important;

    .ag-header-icon,
    .ag-header-cell-text {
      color: #fff !important;
    }
  }

  /* .ag-center-cols-viewport{
			overflow: hidden !important;
		} */

  .ag-root ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  .ag-root ::-webkit-scrollbar-track {
    background: #fff;
  }

  .ag-root ::-webkit-scrollbar-thumb {
    background-color: #cdd2d9;
    border-radius: 20px;
    border: 3px solid #fff;
  }

  .ag-border-cell {
    border-right: 1px solid ag-param(border-color);
  }
}
