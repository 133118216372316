.formWrapper {
  display: flex;
  justify-content: space-between;
}

.formHeader {
  padding-left: 0.5rem;
  background: #f2f5f7;
  display: grid;
  grid-template-columns: 25% 25% 25%;
  column-gap: 12px;
  height: 44px;

  span {
    margin: auto 0px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #7e8c9d;
  }
}

.formRow {
  display: grid;
  margin-left: 10px;
  grid-template-columns: 25% 25% 25%;
  grid-gap: 12px;
  height: 108px;
  align-items: center;

  .description {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #7e8c9d;
  }
  .currentVal {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #0c1725;
  }
}

.formTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .negative {
    min-width: 96px;
    color: #213955;
    border-color: #213955;
    // background: #f2f5f7;
    &:hover {
      color: #f2f5f7;
      background: #69798c;
    }
  }
  .positive {
    min-width: 96px;
    background: #213955;
    &:hover {
      background: #69798c;
    }
  }
  .submitBtn {
    margin: 32px;
    display: flex;
    justify-content: space-between;
    width: 70%;
    max-width: 222px;
    button {
      text-transform: none;
    }
  }
}

.genUnitForm {
  h3 {
    text-transform: capitalize;
    color: #213955;
  }
}
