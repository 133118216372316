.header {
  height: 8.2vh;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(202, 202, 202, 0.3);
}

.copyright {
  position: relative;
  top: 50%;
  left: 46%;
  transform: translate(-50%, -50%);
  text-align: end;
  color: #ffffff;
}

.main {
  height: 100vh;
  iframe {
    border: 0;
    margin-top: 1px;
  }
}

.footer {
  height: 90px;
  background: #213955;
}

.logoWrapper {
  width: 179px;
  margin-left: 120px;
  padding-top: 10px;
  padding-bottom: 10px;
}
