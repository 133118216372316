.supportCard {
  &Item {
    display: grid;
    grid-template-columns: repeat(2, auto) 1fr;
    gap: 12px;

    &Container {
      margin: 0px 24px 0px 24px;
      box-sizing: border-box;
      height: 112px;
      border: 1px solid #cdd2d9;
      border-radius: 8px;
    }

    &Icon {
      width: 18px;
      height: 18px;
      margin-left: 27px;
      margin-top: 25px;
      color: #7fcfd7;
    }

    &Content {
      display: grid;
      margin-top: 24px;
    }

    &Title {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 147.5%;
      letter-spacing: -0.02em;
      color: #0c1725;
    }

    &Description {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: #0c1725;
    }

    &Button {
      display: flex;
      background-color: #213955;
      align-self: center;
      justify-content: center;
      align-items: center;
      justify-self: flex-end;
      margin-top: 36px !important;
      margin-right: 24px !important;
      padding: 12px 24px;
      gap: 8px;
      width: 156px;
      height: 40px;
      box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1),
        inset 0px -1px 0px rgba(14, 14, 44, 0.4);
      border-radius: 8px;
      color: #ffffff !important;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 550;
      font-size: 16px;
      line-height: 19px;
      text-decoration: none !important;
      cursor: pointer;

      &:hover {
        background: #d6d6d6;
        color: #213955 !important;
        text-decoration: none;
        box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1);
      }
    }
  }
}
