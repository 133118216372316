.addResources {
  min-height: 782px;
  background-color: #ffffff;
  margin: 32px;
  padding: 32px;
  border-radius: 8px;
  width: 100%;
}

.resourceList {
  max-width: 800px;
  h3 {
    color: #213955;
  }
}

.wizzardButtons {
  margin: 32px;
  display: flex;
  justify-content: space-between;
  width: 70%;
  max-width: 190px;
  button {
    text-transform: none;
  }
  .negative {
    color: #0c1725;
    background: #f2f5f7;
    &:hover {
      color: #f2f5f7;
      background: #69798c;
    }
  }
  .positive {
    background: #213955;
    &:hover {
      background: #69798c;
    }
  }
}

.resourceContainer {
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 8px;
  cursor: pointer;
  color: #0e0e2c;

  &:hover,
  &.selected {
    color: #568d91;
    border: 1px solid #468388;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 8px #468388);
    border-radius: 16px;
    div[id='resourceicon'].resourceIcon {
      svg[data-testid='SettingsIcon'] {
        color: #568d91;
      }
    }
  }
  .resourceMeta {
    margin-left: 18px;
    h4 {
      margin-bottom: 0;
    }
    p {
      margin-top: 8px;
    }
  }
}
