.successVariant {
  color: #213955 !important;
  background-color: #f2f5f7 !important;
  border: 1px solid #213955 !important;
  //width: 600
}
.errorVariant {
  color: #b31d3b !important;
  background-color: #f2f5f7 !important;
  border: 1px solid #b31d3b !important;
  //width: 600
}
.infoVariant {
  color: #7fcfd7 !important;
  background-color: #f2f5f7 !important;
  border: 1px solid #7fcfd7 !important;
  //width: 600
}
