.wrapper {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.pageTitle {
  font-size: 24px;
  color: var(--primary);
  margin-top: 0;
}
.imageWrapper {
  height: 100%;
}

.formWrapper {
  margin: 2rem;
}

.formWrapperLogo {
  width: 14rem;
}

.formWrapperInputs {
  margin: 25%;
  h1 {
    margin-bottom: 39px;
  }
}

.formWrapperInputsActions {
  display: inline-grid;
  width: 100%;
  grid-gap: 56px;
}

.submitBtn {
  background: #213955;
  &:hover {
    background: #69798c;
  }
  text-transform: none !important;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
