.api-keys-page {
  width: 100%;
  height: 100%;
}
.table-toolbar {
  border: 1px solid #cdd2d9;
  box-sizing: border-box;
}
.api-keys-wrapper {
  padding: 33px 33px 33px 16px;
  height: 100vh;
  background-color: var(--page-background);
}
.add-api-key-btn {
  color: #468388 !important;
  box-shadow: none !important;
  text-transform: none !important;
  background-color: transparent !important;
  border-radius: 8px !important;
  max-height: 42px;

  &:hover {
    background: #468388 !important;
    color: white !important;
  }

  &.disabled {
    color: #7e8c9d !important;
  }

  & + .toolbarbtn {
    margin-left: 5px;
  }
}

.table-pagination {
  p,
  div {
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 17px !important;
    letter-spacing: 0.05em !important;
    color: #69798c !important;
  }

  p {
    margin: 0;
  }
}

.table-row-head {
  background-color: #f2f5f7;
  .table-cell {
    padding: 11px 11px 11px 16px;
    color: #8895a4;
  }
}

.table-row-body {
  td {
    color: #232e3d !important;
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 28px !important;
  }

  a {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #568d91;
  }
}
