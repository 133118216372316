// display flex align items to center
.timeZoneSelector {
  display: flex;
  align-items: center;
}

.advisor {
  color: #213955;
}

.container {
  min-width: 120px;
  margin: 2rem 1rem 2rem 0;
}
